@import "~@ui/styles/tools";
@import "@app/styles/variables";

.banner {
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.content {
    position: relative;
    z-index: 1;
    padding: 20px 40px;
    min-height: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include media-tablet {
        min-height: 140px;
    }

    @include media-tablet-portrait {
        padding: 10px 20px;
        flex-direction: column;
        align-items: unset;
    }
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.description {
    color: $color-primary;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0;
    font-weight: 400;
}

.title {
    position: relative;
    z-index: 1;
    color: $color-title-active;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: 0;
    flex: 1;
    white-space: nowrap;

    b, em, strong {
        font-weight: 600;
        color: $color-primary;
    }

    &_without-image {
        flex: unset;
    }

    @include media-laptop {
        flex: unset;
    }

    @include media-tablet {
        font-size: 32px;
    }

    @include media-tablet-portrait {
        font-size: 20px;
    }
}

.button {
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0;
    padding: 18px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    > img {
        transform: unset;
        margin: 0 !important;
    }

    &-container {
        position: relative;
        z-index: 1;
        white-space: nowrap;
    }

    &-text {
        line-height: 1;
        margin: 0 !important;
    }

    @include media-tablet {
        font-size: 16px;
        padding: 14px 16px;
    }

    @include media-tablet-portrait {
        font-size: 12px;
        padding: 12px;
    }
}

.image-container {
    position: relative;
    width: 440px;

    @include media-tablet-portrait {
        position: absolute;
        width: auto;
        height: 100%;
        right: -20px;
    }

    @include media-mobile-xs {
        display: none;
    }

    &__hide {
        @include media-laptop {
            display: none;
        }
    }
}

.image {
    height: auto !important;
    top: 50% !important;
    transform: translateY(-50%);

    @include media-tablet-portrait {
        left: unset !important;
        width: auto !important;
        height: 120% !important;
        transform: unset;
        top: calc(-10px - 20%) !important;
    }
}
